import $, { event } from "jquery";

$(document).ready(function () {
    $(".finalFormFail").hide();
    $(".finalFormSuccess").hide();
    $("#successSecondStep").hide();
    $(".formstatus").hide();
    let current_fs, next_fs, previous_fs; //fieldsets
    let opacity;
    let answers = [];

    $("#tellMeMoreBtn").on("click", function (ev) {
        ev.preventDefault();
        $("#successFirstStep").hide();
        $("#successSecondStep").show();
    });

    $(".debtFormBtn").on("click", function () {
        current_fs = $(this).parent().parent().parent();
        next_fs = current_fs.next();

        answers.push($(this).data("value"));

        //Add Class Active
        $(".progressbar li")
            .eq($("fieldset").index(next_fs))
            .addClass("active");

        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate(
            { opacity: 0 },
            {
                step: function (now) {
                    // for making fieldset appear animation
                    opacity = 1 - now;

                    current_fs.css({
                        display: "none",
                        position: "relative",
                    });
                    next_fs.css({ opacity: opacity });
                },
                duration: 600,
            }
        );

        if (answers.length === 5) {
            let success = true;
            $(".progressbar").hide();
            if (answers[1] == 1 || answers[2] == 1 || answers[4] == 1) {
                success = false;
            }

            if (success === true) {
                $(".scotland").val(scotland[answers[0] - 1]);
                $(".howMuchDebt").val(debt[answers[1] - 1]);
                $(".employmentStatus").val(employment[answers[2] - 1]);
                $(".howMuchPayingNow").val(payingNow[answers[3] - 1]);
                $(".howMuchAfford").val(payingNow[answers[4] - 1]);

                $(".finalFormSuccess").show();
                // show success message
                return;
            } else {
                // open fail message
                $(".finalFormFail").show();
            }
        }
    });

    // get the form elements defined in your form HTML above

    var button = document.getElementById("eligibleSuccessFormBtn");
    var status = document.getElementById("formstatus");

    const form = {
        action: "https://formspree.io/f/xvovnpbp",
        method: "POST",
    };

    // Success and Error functions for after the form is submitted

    function success() {
        console.log("send success");
        // check to see if user is on wizard page: if yes, redirect to homepage
        if (window.location.pathname.includes("wizard.html")) {
            window.location.href = "https://www.debtbusters.scot?submit=true";
        }
        // else change the form status
        $(".formWrapper").hide();
        $(".formstatus").html(
            '<div class="alert alert-success" role="alert">Thanks! We\'ll be in touch soon.</div>'
        );
        $(".formstatus").show();
    }

    function error() {
        console.log("send failed");

        $(".formWrapper").hide();
        $(".formstatus").html(
            '<div class="alert alert-danger" role="alert">Oops! There was a problem. Please try again later.</div>'
        );
        $(".formstatus").show();
    }

    // handle the form submission event
    $(".eligibleSuccessFormBtn").on("click", function (ev) {
        ev.preventDefault();

        let checked = true;
        // use this function if have checkbox in final form
        // let checked = false;
        // // check confirmation button checked
        // $(".formCheck").each(function (i) {
        //     if ($(this).prop("checked")) {
        //         checked = true;
        //     }
        // });

        let valid = true;
        let querySource = getUrlParameter("source");
        const source = querySource == "" ? "organic" : querySource;

        var data = new FormData();
        data.append("scotland", scotland[answers[0] - 1]);
        data.append("howMuchDebt", debt[answers[1] - 1]);
        data.append("employmentStatus", employment[answers[2] - 1]);
        data.append("howMuchPayingNow", payingNow[answers[3] - 1]);
        data.append("howMuchAfford", payingNow[answers[4] - 1]);
        data.append("lead_source", source);

        const firstName = $(this)
            .closest(".formWrapper")
            .find("input#firstName")
            .val();
        // Use this if have lastname field
        // const lastName = $(this)
        //     .closest(".formWrapper")
        //     .find("input#lastName")
        //     .val();
        const lastName = "NoLastName";
        const phone = $(this).closest(".formWrapper").find("input#phone").val();
        // Use this if have email field in form
        // const email = $(this).closest(".formWrapper").find("input#email").val();
        const email = "nick.s@thedebtbusters.net";

        // if (
        //     phone === "" ||
        //     email === "" ||
        //     firstName === "" ||
        //     lastName === ""
        // ) {
        //     valid = false;
        // }

        if (phone === "" || firstName === "") {
            valid = false;
        }
        data.append("firstname", firstName);
        data.append("lastname", lastName);
        data.append("phone_mobile", phone);
        data.append("phone", phone);
        data.append("_replyto", email);
        data.append("email", email);

        if (!checked || !valid) {
            console.log("error - either invalid or not checked");
            return false;
        }

        // Display the key/value pairs in form for testing
        // var formDataEntries = data.entries(),
        //     formDataEntry = formDataEntries.next(),
        //     pair;
        // while (!formDataEntry.done) {
        //     pair = formDataEntry.value;
        //     console.log(pair[0] + ", " + pair[1]);
        //     formDataEntry = formDataEntries.next();
        // }

        ajax(form.method, form.action, data, success, error);
    });
});

// helper function for sending an AJAX request

function ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = function () {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
            success(xhr.response, xhr.responseType);
        } else {
            error(xhr.status, xhr.response, xhr.responseType);
        }
    };
    xhr.send(data);
}

const scotland = ["No", "Yes"];
const debt = [
    "£0 - £4k",
    "£4k - £10k",
    "£10k- £15k",
    "£15k - £20k",
    "£20k - £30k",
    "Over £30k",
];
const employment = [
    "Unemployed",
    "Employed Full Time",
    "Employed Part Time",
    "Self Employed",
    "Student",
    "Retired",
];
const payingNow = [
    "£0 - £99",
    "£100 - £149",
    "£150 - £199",
    "£200 - £249",
    "£250 - £299",
    "Over £300",
];
const afford = [
    "£0 - £99",
    "£100 - £149",
    "£150 - £199",
    "£200 - £249",
    "£250 - £299",
    "Over £300",
];

function getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    var results = regex.exec(location.search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}
